.round-white-bg {
    border-radius: 50%;
    background-color: white;
    padding: 8px;
  }
  
  .box-shadow {
    box-shadow: 10.5px 30px 1000px #14361B;
    
    /* Handy for boxes but doesn't work well on transparent images */
  }
  
  .hello{
    -moz-box-shadow: inset 50px 50px 100px #14361B;
    -webkit-box-shadow: inset 50px 50px 100px #14361B;
  }

  .single-card:hover button {
    background-color: white;
  }
  
  .single-card:hover button .right-icon {
    color: #ED2025;
  }

  .playfair-font{
    font-family: "Playfair Display", serif;
}