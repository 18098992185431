.nav-icon-5 {
  width: 30px;
  height: 24px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin: auto;
}

.nav-icon-5 span {
  position: absolute;
  border-radius: 1px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 2px;
  transition-duration: 500ms;
}

.nav-icon-5 span:nth-child(1) {
  top: 0px;
  left: 0px;
}

.nav-icon-5 span:nth-child(2) {
  top: 11px;
  left: 0px;
  opacity: 1;
}

.nav-icon-5 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
}

.nav-icon-5.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 11px;
}

.nav-icon-5.open span:nth-child(2) {
  opacity: 0;
}

.nav-icon-5.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 11px;
}
