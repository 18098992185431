@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 30px 30px;
  background: white;
  white-space: nowrap;
  /* position: relative; */
}

.logos:before,
.logos:after {
  /* position: absolute; */
  top: 0;
  width: 250px;
  height: auto;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-flex;
  animation: 20s slide infinite linear;
}

.logos-slide img {
  /* height: 100px; */
  width: 100%;
  height: 50px;
  /* margin: 0 40px; */
}

.logos-slide .flag {
  margin: 0 40px;
}
